import React from "react";

export default props => (
  <svg
    className="logo"
    viewBox="0 0 100 86.60254037844386"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <polygon
      style={{ fill: "rgb(54, 71, 96)" }}
      points="77.59880955544251,86.60254037844386 0,86.60254037844386 50,0 55.600297611139375,9.700000000000001 16.800892833418114,76.90254037844386 71.99851194430313,76.90254037844386 "
    />
    <polygon
      style={{ fill: "rgb(86,120,169)" }}
      points="100,86.60254037844386 61.20059522227874,19.400000000000002 33.60178566683623,67.20254037844386 66.39821433316376,67.20254037844386 60.79791672202439,57.50254037844386 50.40267850025433,57.50254037844386 61.20059522227874,38.800000000000004 88.79940477772125,86.60254037844386 "
    />
  </svg>
);
